<template>
  <article class="contenedor-hijo">
    <div class="container">
      <div class="row justify-content-center justify-content-md-between mb-3 ml-2 ml-sm-0">
        <div class="col-auto px-0">
          <mdb-btn
            flat
            dark-waves
            icon="arrow-left"
            type="button"
            title="Regresar"
            @click="$router.go(-1)"
          >
            Regresar
          </mdb-btn>
        </div>
        <div class="col-auto px-0">
          <mdb-btn
            flat
            dark-waves
            icon="sync"
            type="button"
            title="Actualizar estatus"
            @click="mostrarModalEditarEstatus= !mostrarModalEditarEstatus"
          >
            Actualizar estatus
          </mdb-btn>
        </div>
      </div>
      <div
        v-if="cargando"
        class="mensaje-no-items py-3"
      >
        <LoaderKel />
        <p class="texto mt-2">
          Cargando
        </p>
      </div>
      <div v-else>
        <div class="encabezado-detalles">
          <header class="h4-responsive text-center pt-3">Detalles de la devolución</header>
          <div
            v-if="devolucion && devolucion.length"
            class="row mx-0 mt-2"
          >
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Estado</span>
              <p
                :class="!(devolucion && devolucion[0].est_dev)
                  ?'text-muted font-italic user-select-none'
                  : asignarColorEstado(this.devolucion[0].est_dev) + ' font-weight-bold'"
              >
                {{
                  devolucion && devolucion[0].est_dev
                    ? devolucion[0].est_dev
                    : 'Sin especificar'
                }}
              </p>
            </div>
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Devolución número</span>
              <p class="text-capitalize">{{ this.$route.params.id }}</p>
            </div>
            <div class="col-12 col-sm-4">
              <span class="small-block font-titulo">Fecha</span>
              <p :class="devolucion[0] && devolucion[0].fec_dev ? 'text-capitalize' : 'text-muted font-italic user-select-none'">
                {{ devolucion[0] && devolucion[0].fec_dev ? devolucion[0].fec_dev : 'Sin fecha especificada' }}
              </p>
            </div>
            <div class="col-12 col-xl-4">
              <span class="small-block font-titulo">Cliente, Sucursal</span>
              <p>
                <span :class="devolucion[0] && !devolucion[0].nom_emp.length ? 'text-muted font-italic user-select-none' : 'text-capitalize'">
                  {{ devolucion[0] && devolucion[0].nom_emp ? devolucion[0].nom_emp : 'Cliente no especificado' }},
                </span>
                <span :class="devolucion[0] && !devolucion[0].nom_suc.length ? 'text-muted font-italic user-select-none' : 'text-capitalize'">
                  {{ devolucion[0] && devolucion[0].nom_suc ? devolucion[0].nom_suc : 'Sucursal no especificada' }}
                </span>
              </p>
            </div>
            <div class="col-12 col-xl-4">
              <span class="small-block font-titulo">Vendedor</span>
              <p>
                <span :class="devolucion[0] && (!devolucion[0].nom_per || !devolucion[0].ape_per) ? 'text-muted font-italic user-select-none' : 'text-capitalize'">
                  {{ devolucion[0] && (devolucion[0].nom_per || devolucion[0].ape_per) ? devolucion[0].nom_per + ' ' + devolucion[0].ape_per : 'Vendedor no especificado' }}
                </span>
              </p>
            </div>
            <div class="col-12 col-md-6 col-xl-4">
              <span class="small-block font-titulo">Dirección</span>
              <p class="text-capitalize">
                {{ devolucion[0] && devolucion[0].dir_suc }},
                {{ devolucion[0] && devolucion[0].sec_zon }},
                {{ devolucion[0] && devolucion[0].ciu_zon }},
                {{ devolucion[0] && devolucion[0].est_zon }}.
              </p>
            </div>
            <div class="col-12 col-md-6 col-xl">
              <span class="small-block font-titulo">Observación</span>
              <p
                :class="{
                  'text-muted font-italic user-select-none': devolucion[0] && !devolucion[0].obs_dev.length,
                }"
              >
                {{
                  (devolucion[0] && devolucion[0].obs_dev && devolucion[0].obs_dev) ||
                  'Sin especificar'
                }}
              </p>
            </div>
          </div>
          <div
            v-else
            class="mensaje-no-items py-2"
          >
            <font-awesome-icon icon="file-invoice" size="5x" class="icono" />
            <p class="texto">Sin detalles de la devolución</p>
          </div>
        </div>
        <!-- Devolución plantilla traida de actividad.vue -->
        <ul class="table devolucion">
          <li class="encabezado sticky">
            <header class="h4-responsive">Productos en devolución</header>
            <p class="descripcion">
              Productos listados: {{ notasDevolucion && notasDevolucion.length }}
            </p>
            <div class="thead claro">
              <div class="col">
                Nombre
                <small class="d-sm-block small-block">Categoría</small>
              </div>
              <div class="col-12 col-sm-3">
                Descripción
                <small class="d-sm-block small-block">Empaque - Código</small>
              </div>
              <div class="col-12 col-sm-2 text-right">
                Cantidad
                <small class="d-sm-block small-block">Unidad</small>
              </div>
              <div class="col">
                Observación
              </div>
            </div>
          </li>
          <li
            v-if="notasDevolucion && !notasDevolucion.length"
            class="mensaje-no-items py-3"
          >
            <font-awesome-icon icon="file-invoice" size="5x" class="icono" />
            <p class="texto text-center">No existen productos en devolución</p>
          </li>
          <li
            class="contenido"
            v-for="(producto, i) in notasDevolucion"
            :key="producto.id_pre+'-'+i"
          >
            <div
              class="col"
              data-columna="Nombre / Categoría"
            >
              <p>
                {{ producto.nom_pro }}
                <span class="small-block">
                  {{ producto.nom_cat && producto.nom_cat }}
                </span>
              </p>
            </div>
            <div
              class="col-12 col-sm-3"
              data-columna="Descripción / Empaque - Código"
            >
              <p>
                {{ producto.des_pre }}
                <span class="small-block">
                  {{ producto.tip_pre }} - {{ producto.cod_pre ? producto.cod_pre : 0 }}
                </span>
              </p>
            </div>
            <div
              class="cl-12 col-sm-2"
              data-columna="Cantidad / Unidad"
            >
              <p class="text-right">
                {{ producto.can_nd}}
                <span class="small-block">
                  {{ producto.uni_paq_pre ? producto.uni_paq_pre : '-' }}
                </span>
              </p>
            </div>
            <div
              class="col"
              data-columna="Observación"
            >
              <p>
                {{producto.obs_nd.slice(0,1).toUpperCase() + producto.obs_nd.slice(1)}}
              </p>
            </div>
          </li>
        </ul>
      </div>
      <AlertaMensaje
        :alertaMensaje="alertaMensaje"
        @cerrar="alertaMensaje.contenido = ''"
      />
      <mdb-modal
        centered
        elegant
        scrollable
        :show="mostrarModalEditarEstatus"
        @close="mostrarModalEditarEstatus=false"
      >
        <mdb-modal-header>
          <mdb-modal-title>Actualizar estatus de la devolución</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body>
          <div class="md-form md-outline outline-select mt-0 mb-5">
            <select
              v-model="estatusDevolucion"
              class="custom-select"
              id="estatus-select"
            >
              <option value="" class="d-none" disabled> Seleccionar</option>
              <option
                v-for="(estatus, i) in estatusDisponible"
                :key="estatus+'-'+i"
                :value="estatus"
              >
                {{estatus}}
              </option>
            </select>
            <label
              for="estatus-select"
              :class="estatusDevolucion.length ? 'label-active' : 'label-inactive'"
            >
              Estatus
            </label>
          </div>
          <div class="text-center">
            <mdb-btn
              type="submit"
              color="primario"
              icon="sync"
              class="mx-0"
              @click="editarEstatus"
              :disabled="botonDeshabilitado"
            >
              Actualizar
            </mdb-btn>
          </div>
        </mdb-modal-body>
      </mdb-modal>
    </div>
  </article>
</template>

<script>
import { apiPost } from '@/funciones/api.js'
import {
  mdbBtn,
  mdbModal,
  mdbModalHeader,
  mdbModalTitle,
  mdbModalBody
} from 'mdbvue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import { asignarColorEstado } from '@/funciones/funciones.js'
import LoaderKel from '@/components/LoaderKel.vue'

export default {
  name: 'Devolucion',
  components: {
    mdbBtn,
    AlertaMensaje,
    mdbModal,
    mdbModalHeader,
    mdbModalTitle,
    mdbModalBody,
    LoaderKel
  },
  data () {
    return {
      asignarColorEstado,
      devolucion: [],
      notasDevolucion: [],
      estatusDevolucionArray: ['En Espera', 'Procesada'],
      botonDeshabilitado: false,
      // Editar estatus
      mostrarModalEditarEstatus: false,
      estatusDevolucion: '',
      // Otros
      modalConfirmacionEliminar: false,
      cargando: false,
      alertaMensaje: {
        contenido: ''
      }
    }
  },
  mounted () {
    this.obtenerDetallesDevolucion()
  },
  computed: {
    estatusDisponible () {
      if (this.devolucion.length) {
        return this.estatusDevolucionArray.filter(estatus => estatus !== this.devolucion[0].est_dev) || this.estatusDevolucionArray
      }
      return this.estatusDevolucionArray
    }
  },
  methods: {
    async obtenerDetallesDevolucion () {
      this.cargando = true
      if (!this.$route.params.id) {
        this.$router.push('/devoluciones')
        return
      }
      try {
        const { data } = await apiPost(
          { s: 'devolucionMv' },
          'dev=' + this.$route.params.id
        )
        if (data) {
          this.devolucion = data.dev
          this.notasDevolucion = data.not
        }
        this.cargando = false
      } catch (error) {
        this.alertaMensaje = {
          contenido: 'No se pudo obtener la devolución',
          tipo: 'error'
        }
        this.cargando = false
        this.$router.push('/devoluciones')
      }
    },
    editarEstatus () {
      if (!this.estatusDevolucion) {
        this.alertaMensaje = {
          contenido: 'Debes seleccionar un estatus para actualizar',
          tipo: 'advertencia'
        }
        return
      }
      this.botonDeshabilitado = true
      const variablesPost = {
        dev: this.$route.params.id,
        est: this.estatusDevolucion
      }
      apiPost({ s: 'devolucionEdiMv' }, JSON.stringify(variablesPost))
        .then((response) => {
          if (response.data.r) {
            this.alertaMensaje = {
              contenido: 'Estatus de la devolución actualizada correctamente',
              tipo: 'correcto'
            }
            this.botonDeshabilitado = false
            this.mostrarModalEditarEstatus = false
            this.estatusDevolucion = ''
            this.obtenerDetallesDevolucion()
            return
          }
          this.alertaMensaje = {
            contenido: `${response.data.e}`,
            tipo: 'error'
          }
          this.botonDeshabilitado = false
        })
        .catch(() => {
          this.botonDeshabilitado = false
          this.alertaMensaje = {
            contenido: 'Ocurrió un error actualizando el estatus. Por favor intenta de nuevo',
            tipo: 'error'
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.table.devolucion {
  margin: 2rem auto 3rem;

  // Define los breakpoints para el responsive de ul.table
  $breakpointToChange: "576px";
  @include ul-table-responsive-at ($breakpointToChange);
}
</style>
